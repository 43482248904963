<template>
  <v-pagination
    outlined
    dark
    style="background: #e5e5e5;"
    color="#f5f5f5"
    total-visible="7"
    v-model="get_relatorios_filtros.page"
    :length="get_relatorios_filtros.totalPages"
    @input="setPage($event)"
  ></v-pagination>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_relatorios_filtros"])
  },
  methods: {
    ...mapActions(["listar_relatorios"]),
    setPage(page) {
      this.get_relatorios_filtros.page = page;
      this.listar_relatorios();
    }
  }
};
</script>
